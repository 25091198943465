<template>
  <v-container
    style="margin: auto"
    class="blog fill-height justify-center align-center"
  >
    <h1 style="margin-bottom: 3%; margin-top: 3%">
      How to Cite YouTube Videos in Chicago Format
    </h1>

    <v-row>
      <v-col cols="12" md="10" lg="10" xl="10">
        To use the information you find with Pop Mystic’s movie line search
        engine, you will need to cite it, and this article is intended as a
        quick reference for citing the movies and television shows you want to
        quote or analyze in the Chicago format (sometimes called Turabian). This
        guide will demonstrate the proper use of Chicago format’s Author-Date
        style of citation. For Notes and Bibliography style, consult the
        excellent in-depth and detailed information available at
        <a href="https://www.scribbr.com/chicago-style/film-citations/"
          >Scribbr.com</a
        >
        of which this guide is a synopsis.
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" align="center" justify="center">
        <v-img
          class="mx-auto"
          src="../../assets/chicago/chicago-manual.jpg"
          contain
          max-width="150px"
        ></v-img>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>Bibliography Citation</h2>
    </v-row>
    <v-row style="margin-bottom: 20px" align="center" justify="center">
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-img
          class="mx-auto"
          src="../../assets/chicago/food-youtube.jpg"
          contain
          max-height="300px"
        ></v-img>
      </v-col>
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text>
            Author last name, First name. Year. “Video Title.” Additional
            information. Month Day, Year. Format, Video length. URL.
          </v-card-text>
        </v-card>
        <br />

        Example
        <v-card color="secondary">
          <v-card-text>
            Ragusea, Adam. 2020. “What’s the point of cooking at home anymore?”
            Feb 17, 2020. Cooking video, 13:43.
            https://www.youtube.com/watch?v=UNHtj7QtCdk.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>In-Text Citation</h2>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text> (AuthorLast Year, 00:00:00) </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            Food delivery services may actually end up reducing waste, as well
            as carbon emissions (Ragusea 2020, 09:11).
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChicagoYoutube",
  data: () => ({
    paragraphs: [
      {
        text: "Just as you cite a book or magazine in your research papers, you have to cite the movies that have contributed to your position. To use the TV and movie quotes you’ll find on <Pop Mystic with link> in any kind of real research, you will need to cite its source. This article is a reference for APA style (7th Edition) citations for the quotes from movies and television shows you want to use to support your arguments. Much of the information presented here is a quick synopsis of the excellent and detailed information available at Scribbr.com <https://www.scribbr.com/category/apa-style/>",
        imageSrc: "src/assets/amazon-button1.png",
      },
    ],
  }),

  components: {},
};
</script>

<style scoped>
.blog {
  font-weight: 600;
  color: black;
}
h2 {
  text-decoration: underline;
  text-align: center;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
.container {
  max-width: 900px;
}
@media (min-width: 1904px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 900px;
  }
}
</style>
